import { createGlobalStyle } from "styled-components";
import reset from 'styled-reset';

const GlobalStyle = createGlobalStyle`

    ${reset}

    * {
        box-sizing: border-box;
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    html, body, #root {
        /* background-color: #121316; */
        background-color: #000000;
        margin: 0;
        padding: 0;
        height: 100%;
    }

    .scrollBar::-webkit-scrollbar {
        width: 8px;
    }

    .scrollBar::-webkit-scrollbar-thumb {
        height: 30%;
        background: rgba(235, 235, 235, 0.1);
        
        border-radius: 10px;
    }

    .scrollBar::-webkit-scrollbar-track {
        background: #2c2c2c
    }

    h1, p {
        color: #f8f8f8cc;
    }

`;

export default GlobalStyle;