import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en.json';
import ko from './ko.json';
import ja from './ja.json';
import cn from './cn.json';
import fr from './fr.json';
import es from './es.json';
import de from './de.json';

export const languages = ['en','ko', 'ja', 'cn', 'fr', 'es', 'de'] as const;
export type Languages = typeof languages[number];

const resources = {
    en: { translation: en },
    ko: { translation: ko },
    ja: { translation: ja },
    cn: { translation: cn },
    fr: { translation: fr },
    es: { translation: es },
    de: { translation: de }
}

const userLanguage = window.navigator.language || window.navigator.language;

i18n.use(initReactI18next).init({
    resources,
    lng: localStorage.getItem('language') || userLanguage || 'ko',
    fallbackLng: 'ko',
    keySeparator: false,
    interpolation: {
        escapeValue: false
    }
});

export default i18n;