import { observable } from 'mobx';

interface ScenarioStatus {
    scenarioList: [string];
    change_ScenarioList: (scenarioList: [string]) => void;
}

export const ScenarioStore = observable<ScenarioStatus>({
    scenarioList: [''],
    change_ScenarioList(scenarioList: [string]) {
        this.scenarioList = scenarioList;
    }
});