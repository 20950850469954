import Lottie from 'react-lottie';
import Lottie_Loading from 'assets/lottie/loading.json';
import styled from 'styled-components';

const Loading = () => {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: Lottie_Loading,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    return (<>
        <Container>
            <Lottie 
                options={defaultOptions}
                height={180}
                width={180}
                isClickToPauseDisabled={true}
            />
        </Container>
    </>);

}

export default Loading;

const Container = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
`;