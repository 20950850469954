import React, { Suspense } from "react";
import { useRoutes } from 'react-router-dom';
import { Provider } from 'mobx-react';
import GlobalStyle from "./globalStyle";
import routes from 'routers';
import stores from 'stores';
import Contents from "layouts/Contents";
import TopNavigator from "layouts/TopNavigator";
import SideNavigator from "layouts/SideNavigator";
import Loading from "components/Loading";
import Config from 'config';
import {NextUIProvider} from "@nextui-org/react";
import {ThemeProvider as NextThemesProvider} from "next-themes";

const App:React.FC = () => {

  const auth = useRoutes(routes.AUTH);
  const un_authorized = useRoutes(routes.UN_AUTHORIZED);
  const base_url = Config.base_url;

  const AUTH = () => {
    return (
      <Provider {...stores}>
        <NextUIProvider>
        <GlobalStyle />
          <NextThemesProvider attribute="class" defaultTheme="dark">
            <Suspense fallback={<Loading />}>
                  <TopNavigator />
                  <SideNavigator />
                  <Contents className="scrollBar">
                      {auth}
                  </Contents>
            </Suspense>
          </NextThemesProvider>
        </NextUIProvider>
      </Provider>
    )
  }

  const UN_AUTHORIZED = () => {
    return (<>
        <GlobalStyle />
        <Suspense fallback={<div>Loading</div>}>
          {un_authorized}
        </Suspense>
    </>)
  }

  const ROUTE = sessionStorage.getItem('auth') ? AUTH : UN_AUTHORIZED;

  return (
    <ROUTE />
  )

}

export default App;