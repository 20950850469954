import styled from 'styled-components';

const Contents = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 49px;
    left: 230px;
    width: calc(100% - 230px);
    height: calc(100% - 49px);
    overflow: auto;
    background-color: #000;
    @media (max-width: 800px) {
        width: 100vw;
        left: 0px;
    }
`;

export default Contents;
