import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {Dropdown, DropdownTrigger, DropdownMenu, DropdownItem} from "@nextui-org/react";
import Translate_Icon from 'assets/icons/translate.png';

const TranslationOption = () => {

    const { i18n } = useTranslation();
    const handleChangeLanguage = (lang: string) => {
        i18n.changeLanguage(lang);
        localStorage.setItem('language', lang)
    }

    const items = [
        {
          key: "ko",
          label: "한국어",
        },
        {
          key: "en",
          label: "English",
        },
        {
          key: "ja",
          label: "日本語",
        },
        {
            key: "cn",
            label: "中國語",
        },
        {
          key: "fr",
          label: "Français",
        },
        {
            key: "es",
            label: "Español",
        },
        {
            key: "de",
            label: "Deutsch",
        },
      ];

    return(<>
        <Dropdown>
            <DropdownTrigger>
                <MenuItem 
                    style={{width: '50px', height: '100%'}}
                >
                    <MenuIcon src={Translate_Icon} />
                    <p></p>
                </MenuItem>
            </DropdownTrigger>
            <DropdownMenu aria-label="Dynamic Actions" items={items}>
                {(item) => (
                <DropdownItem
                    key={item.key}
                    onClick={()=>{handleChangeLanguage(item.key)}}
                >
                    {item.label}
                </DropdownItem>
                )}
            </DropdownMenu>
        </Dropdown>
    </>)
}

export default TranslationOption;

const MenuItem = styled.div`
    height: 100%;
    width: 50px;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 9px;
    transition: color 0.5s ease;
    &:hover {
        background-color: #333;
        font-size: 10px;
    }
`;

const MenuIcon = styled.img`
    height: 20px;
    margin-bottom: 3px;
`;