import styled from 'styled-components';
import Drop_Down_Icon from 'assets/icons/drop-down.png';
import Instance_Icon from 'assets/icons/instance.png';
import Data_Icon from 'assets/icons/data.png';
import Main_Icon from 'assets/icons/main.png';
import Bill_Icon from 'assets/icons/bill.png';
import License_Icon from 'assets/icons/license.png';
import PC_Icon from 'assets/icons/pc.png';
import Scenario_Run_Icon from 'assets/icons/scenario_run.png';
import Map_Editor_Icon from 'assets/icons/map_editor.png';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Chip } from '@nextui-org/react';

const MobileNavigator = () => {

    const navigate = useNavigate();
    const { t } = useTranslation();
    const [adminPanel, setAdminPanel] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    const _handleAdminPanel = () => {
        setAdminPanel(!adminPanel);
    }
    async function handleLogout() {
        sessionStorage.clear();
        window.location.replace('/');
    }

    const menuItems = [
        { icon: Instance_Icon, label: t('side.instances'), subMenu: [
            { label: t('side.morai_cloud_instance'), route: '/cloud_instances', chip: true },
            { label: t('side.instance_setting'), route: '/instance_setting' },
        ]},
        { icon: Main_Icon, label: t('side.scenario'), subMenu: [
            { label: t('side.scenario_base'), route: '/scenario_base' },
            { label: t('side.scenario_editor'), route: '/scenario_editor' },
            { label: t('side.edgecase_reports'), route: '/edgecase_reports' },
        ]},
        { icon: Map_Editor_Icon, label: t('side.maps'), subMenu: [
            { label: t('side.mapdata_base'), route: '/mapdata_base' },
            { label: t('side.map_editor'), route: '/map_editor' },
        ]},
        { icon: Data_Icon, label: t('side.data_gen'), subMenu: [
            { label: t('side.data_gen'), route: '/data_gen' },
        ]},
        { icon: Bill_Icon, label: t('side.billing'), subMenu: [
            { label: t('side.invoices'), route: '/billing' },
        ]},
        { icon: License_Icon, label: t('side.license'), subMenu: [
            { label: t('side.manage_license'), route: '/license' },
        ]},
        { icon: PC_Icon, label: t('side.desktop'), subMenu: [
            { label: t('side.pc_client'), route: '/desktop' },
        ]}
    ];

    const filteredMenuItems = menuItems.filter(item => 
        item.label.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.subMenu.some(sub => sub.label.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    return (<>
        <Container className='scrollBar'>
            <MenuArea>
            <Profile onMouseDown={_handleAdminPanel}>
                    <Icon src={Drop_Down_Icon} />
                    <p>Morai Admin</p>
                </Profile>
                {adminPanel && <>
                    <AdminPanel onMouseLeave={_handleAdminPanel}>
                        <SubMenu onClick={()=>{navigate('/information')}}>
                            <p className='text'>{t('side.change_personal_info')}</p>
                        </SubMenu>
                        <SubMenu onClick={()=>{navigate('/password')}}>
                            <p className='text'>{t('side.change_password')}</p>
                        </SubMenu>
                        <SubMenu onClick={()=>{navigate('/settings')}}>
                            <p className='text'>{t('side.setting')}</p>
                        </SubMenu>
                        <SubMenu onClick={handleLogout}>
                            <p className='text'>{t('side.logout')}</p>
                        </SubMenu>
                    </AdminPanel>
                </>}
                <SearchBar
                    className='search_box'
                    type='text'
                    placeholder='Search'
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                {filteredMenuItems.map((item, index) => (
                    <div key={index}>
                        <UpperMenu>
                            <Icon src={item.icon} />
                            <p className='text'>{item.label}</p>
                        </UpperMenu>
                        {item.subMenu.map((subItem, subIndex) => (
                            <SubMenu key={subIndex} onClick={() => { navigate(subItem.route) }}>
                                <p className='text'>{subItem.label}</p>
                                {subItem.chip && (
                                    <Chip
                                        variant="shadow"
                                        classNames={{
                                            base: "bg-gradient-to-br from-indigo-500 to-pink-500 border-small border-white/50 shadow-pink-500/30",
                                            content: "drop-shadow shadow-black text-white text-xs",
                                        }}
                                        size='sm'
                                        style={{ marginLeft: 10 }}
                                    >
                                        Beta
                                    </Chip>
                                )}
                            </SubMenu>
                        ))}
                    </div>
                ))}
                <Version>
                    <p className='text'>morai cloud simulation v0.1.0</p>
                </Version>
            </MenuArea>
        </Container>
    </>);

}

export default MobileNavigator;

const Container = styled.div`
    position: fixed;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    top: 49px;
    width: 100%;
    height: 100%;
    background-color: #000;
    overflow-x: hidden;
    border-right: 1px solid #f8f8f833;
    z-index: 10;
    @media (min-width: 800px) {
        display: none;
    }
`;

const MenuArea = styled.div`
    width: 100%;
    overflow-block: auto;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    flex-direction: column;
`;

const Icon = styled.img`
    height: 16px;
    margin-right: 10px;
`;

const Profile = styled.div`
    width: 100%;
    height: 45px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 20px;
    color: #f8f8f833;
    border-bottom: 1px solid #f8f8f833;
    cursor: pointer;
    &:hover {
        background-color: #333;
    }
    &:active {
        font-weight: bold;
    }
    font-size: 14px;
    font-weight: 500;
`;

const SearchBar = styled.input`
    height: 40px;
    width: calc(100%-30px);
    border: 0px;
    background-color: #333;
    border-radius: 10px;
    margin-top: 15px;
    font-size: 13px;
    color: #f8f8f8cc;
    padding-left: 10px;
    margin-left: 10px;
    margin-right: 10px;
`;

const UpperMenu = styled.div`
    width: 100%;
    height: 40px;
    border-top: 1px solid #f8f8f833;
    font-size: 10px;
    font-weight: 600;
    display: flex;
    align-items: center;
    padding-left: 20px;
    margin-top: 15px;
    .text {
        color: #fff;
    }
`;

const SubMenu = styled.div`
    width: 100%;
    height: 45px;
    font-size: 11px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    cursor: pointer;
    &:hover {
        background-color: #333;
        width: 100%;
        transition: all 0.1s;
        .text {
            color: #fff;
        }
    }
    &:active {
        font-weight: bold;
    }
`;

const Version = styled.div`
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    .text {
        font-size: 11px;
        color: #777;
    }
`;

const AdminPanel = styled.div`
    width: 100%;
    height: 180px;
    top: 100px;
`;