import { useState } from 'react';
import styled from 'styled-components';
import Morai_Cloud_Logo from 'assets/morai_logo.png';
import Logout_Icon from 'assets/icons/logout.png';
import Menu_Icon from 'assets/icons/menu.png';
import TranslationOption from 'components/TopNavigator/TranslationOption';
import { useObserver } from 'mobx-react';
import MobileNavigator from 'layouts/MobileNavigator';

const TopNavigator = () => {

    const [mobileMenu, setMobileMenu]= useState(false);

    async function handleLogout() {
        sessionStorage.clear();
        window.location.replace('/');
    }

    const handleMobileMenu = () => {
        setMobileMenu(!mobileMenu);
    }


    return useObserver(() => (<>
        <Container>
            <Navigator>
                <LogoRefresh onClick={()=>{window.location.replace('/')}}>
                    <Logo alt="MORAI CLOUD SIMULATION" src={Morai_Cloud_Logo} />
                    <p className='sub_text'>CLOUD</p>
                </LogoRefresh>
                <Menu>
                    <TranslationOption />
                    <MenuItem onClick={handleLogout}>
                        <MenuIcon src={Logout_Icon} /> 
                    </MenuItem>
                </Menu>
                <MobileMenu>
                    <TranslationOption />
                    <MenuItem onClick={handleMobileMenu}>
                        <MenuIcon src={Menu_Icon} /> 
                    </MenuItem>
                </MobileMenu>
            </Navigator>
        </Container>
        { mobileMenu && <MobileNavigator/> }
    </>));

}

export default TopNavigator;

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
`;

const Navigator = styled.div`
    width: 100%;
    height: 49px;
    background-color: #000;
    display: flex;
    padding: 0px 0px 0px 20px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f8f8f833;
    @media screen and (max-width: 789px) {
        padding: 0px 0px 0px 15px;
    }
`;

const LogoRefresh = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    .sub_text {
        font-size: 19px;
        margin-left: 10px;
        font-weight: 400;
        @media screen and (max-width: 400px) {
            font-size: 14px;
        }
    }
`;

const Menu = styled.div`
    display: flex;
    height: 100%;
    justify-content: space-between;
    @media screen and (max-width: 789px) {
        display: none;
    }
`;

const MenuItem = styled.div`
    height: 100%;
    width: 50px;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 9px;
    transition: color 0.5s ease;
    &:hover {
        background-color: #333;
        font-size: 10px;
    }
`;

const MobileMenu = styled.div`
    display: flex;
    height: 100%;
    justify-content: space-between;
    display: none;
    @media screen and (max-width: 789px) {
        display: flex;
    }
`;

const Logo = styled.img`
    height: 1.2em;
    @media screen and (max-width: 400px) {
        height: 15px;
    }
`;

const MenuIcon = styled.img`
    height: 20px;
    margin-bottom: 3px;
`;