import { StateStore } from "./StateStore";
import { ScenarioStore } from "./ScenarioStore";

const useStore = () => {
    return ({
        StateStore,
        ScenarioStore
    });
}

export default useStore;