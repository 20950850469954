console.log(`
%c███╗   ███╗ ██████╗ ██████╗  █████╗ ██╗
%c████╗ ████║██╔═══██╗██╔══██╗██╔══██╗██║
%c██╔████╔██║██║   ██║██████╔╝███████║██║
%c██║╚██╔╝██║██║   ██║██╔══██╗██╔══██║██║
%c██║ ╚═╝ ██║╚██████╔╝██║  ██║██║  ██║██║
%c╚═╝     ╚═╝ ╚═════╝ ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝
`, "color:#3fa8ee", "color:#219fa1", "color:#57CC99", "color:#80ED99", "color:#99FFED","color:#FFFFFF");
console.log('Currently On ' + process.env.NODE_ENV + ' as ' + window.location.host);

const detectBaseUrl = (url?: string) => {
    let base_url:string = '';
    switch (url) {
        case 'localhost:3000':
            base_url = `http://10.52.10.89:8080/`;
            break;
        default:
            base_url = `https://api.morai.cloud/`;
            break;
    }
    return base_url;
};

const headers = {
    Accept: "*",
    "Content-Type": "application/json;charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
    "X-Content-Type-Options": "nosniff",
    "SameSite" : "none",
    Authorization : sessionStorage.getItem('sessionKey'),
    withCredentials: true,
}

const config = {
    base_url: detectBaseUrl(window.location.host),
    headers
};

export default config;