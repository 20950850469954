import { lazy } from "react";

const Login = lazy(()=> import('pages/Login'));
const Home = lazy(()=> import('pages/Home'));
const CloudProducts  = lazy(()=> import('pages/Home/CloudProducts'));
const ScenarioProducts = lazy(()=> import('pages/Home/ScenarioProducts'));
const Documents = lazy(()=> import ('pages/Home/Documents'));
const Pricing = lazy(()=> import('pages/Home/Pricing'));

const NotFound = lazy(()=> import('pages/NotFound'));
const Test = lazy(()=> import('pages/Test'));

const Main = lazy(()=> import('pages/Main'));

const Information = lazy(()=> import('pages/Profile/Information'));
const Password = lazy(()=> import('pages/Profile/Password'));
const Settings = lazy(()=> import('pages/Profile/Settings'));

const CloudInstances = lazy(()=> import('pages/Instances/CloudInstances'));
const InstanceSetting = lazy(()=> import('pages/Instances/InstanceSetting'));
const RemoteInstance = lazy(() => import('pages/Instances/RemoteInstance'));

const ScenarioBase = lazy(()=> import('pages/Scenario/ScenarioBase'));
const ScenarioTest = lazy(()=> import('pages/Scenario/ScenarioTest'));
const ScenarioEditor = lazy(()=> import('pages/Scenario/ScenarioEditor'));
const EdgecaseReports = lazy(()=> import('pages/Scenario/EdgecaseReports'));

const MapDataBase = lazy(()=> import('pages/Maps/MapDataBase'));
const MapEditor = lazy(()=> import('pages/Maps/MapEditor'));

const DataGen = lazy(()=> import('pages/DataGen/index'));

const Billing = lazy(()=> import('pages/Billing'));
const License = lazy(()=> import('pages/License'));
const Desktop = lazy(()=> import('pages/Desktop'));

const AUTH = [
    {
        path: '/',
        element: <Main />
    },
    {
        path: '',
        element: <Main />
    },
    {
        path: 'login',
        element: <Main />
    },
    {
        path: 'information',
        element: <Information />
    },
    {
        path: 'password',
        element: <Password />
    },
    {
        path: 'settings',
        element: <Settings />
    },
    {
        path: 'cloud_instances',
        element: <CloudInstances />
    },
    {
        path: 'instance_setting',
        element: <InstanceSetting />
    },
    {
        path: 'remote_instance',
        element: <RemoteInstance />
    },
    {
        path: 'scenario_base',
        element: <ScenarioBase />
    },
    {
        path: 'scenario_editor',
        element: <ScenarioEditor />
    },
    {
        path: 'scenario_test',
        element: <ScenarioTest />
    },
    {
        path: 'edgecase_reports',
        element: <EdgecaseReports />
    },
    {
        path: 'mapdata_base',
        element: <MapDataBase />
    },
    {
        path: 'map_editor',
        element: <MapEditor />
    },
    {
        path: 'data_gen',
        element: <DataGen />
    },
    {
        path: 'billing',
        element: <Billing />
    },
    {
        path: 'license',
        element: <License />
    },
    {
        path: 'desktop',
        element: <Desktop />
    },
    {
        path: 'test',
        element: <Test />
    },
    {
        path: '*',
        element: <NotFound />
    }
]

const UN_AUTHORIZED = [
    {
        path: '/',
        element: <Home />
    },
    {
        path: '',
        element: <Home />
    },
    {
        path: 'cloud_products',
        element: <CloudProducts />
    },
    {
        path: 'scenario_products',
        element: <ScenarioProducts />
    },
    {
        path: 'documents',
        element: <Documents />
    },
    {
        path: 'pricing',
        element: <Pricing />
    },
    {
        path: 'login',
        element: <Login />
    },
    {
        path: '*',
        element: <NotFound />
    }
]

const routers = { UN_AUTHORIZED, AUTH }

export default routers;