import { observable } from 'mobx';

interface StateStatus {
    default: any;
    topNavigationStatus: boolean;
    sideNavigationStatus: boolean;
    translationOption: boolean;
    change_TopNavigationStatus: (topNavigationStatus: boolean) => void;
    change_SideNavigationStatus: (sideNavigationStatus: boolean) => void;
    change_TranslationOption: (translationOption: boolean) => void;
};

export const StateStore = observable<StateStatus>({
    default: {},
    topNavigationStatus: false,
    sideNavigationStatus: false,
    translationOption: false,
    change_TopNavigationStatus(topNavigationStatus: boolean) {
        this.topNavigationStatus = !topNavigationStatus;
    },
    change_SideNavigationStatus(sideNavigationStatus: boolean) {
        this.sideNavigationStatus = !sideNavigationStatus;
    },
    change_TranslationOption() {
        this.translationOption = !this.translationOption
    }
});